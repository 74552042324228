export enum MenuOption {
  Home = 'home',
  Draft = 'draft',
  NewWashRequests = 'new-wash-requests',
  NeedsAction = 'needs-action',
  Pending = 'pending',
  Hold = 'hold',
  Accepted = 'accepted',
  CreditHold = 'credit-hold',
  InProgress = 'in-progress',
  Completed = 'completed',
  Canceled = 'canceled',
  Rejected = 'rejected',
  Submitted = 'submitted',
  Logout = 'logout',
  DownloadTemplate = 'download-template',
  Expired = 'expired',
  // Schedule options
  Schedule = 'schedule',
  ActivateTerminal = 'settings/activate-terminal',
  HoursOfOperation = 'settings/hours-of-operation-settings',
  BaySettings = 'settings/bay-settings',
  VelocitySettings = 'settings/velocity-settings',
  Wiki = 'wiki',
  Video = 'video',
  Terms = 'support/terms-and-conditions',
  Reports = 'reports',
  ReportsPortal = 'reports-portal',
  LeanDailyManagementReport = 'lean-daily-management',
  WashMetrics = 'wash-metrics',
  TerminalScorecardWash = 'terminal-scorecard-wash',
  TerminalScorecardDepot = 'terminal-scorecard-depot',
  EhsWasteManagement = 'ehs-waste-management-terminal-view',
  TerminalScorecardIndustrial = 'terminal-scorecard-industrial',
  TerminalScorecardRail = 'terminal-scorecard-rail',
  ShiftLogs = 'shift-logs',
  BusinessPartnerNotification = 'business-partner-notification',
  UserNotification = 'user-notification',
  // Non Conformity Report
  NcrOpened = 'opened',
  NcrInAnalysis = 'in-analysis',
  NcrWaitingForApproval = 'waiting-for-approval',
  NcrCompleted = 'completed',
  NcrCancelled = 'cancelled',
  // Exterior Wash
  ExteriorWashOffer = 'exterior-wash-offer',
  SchneiderPortalCompletions = 'schneider-portal-completions',
  OtherTools = 'other-tools',
  OtherToolsDatalake = 'datalake',
}

export enum Title {
  Home = 'Home',
  Draft = 'Drafts',
  NewWashRequests = 'New Requests',
  NeedsAction = 'Needs Action',
  Pending = 'Pending',
  Hold = 'Hold',
  CreditHold = 'Credit Hold',
  Accepted = 'Accepted',
  OnHold = 'Credit Hold',
  InProgress = 'In Progress',
  Completed = 'Completed',
  Canceled = 'Canceled',
  Rejected = 'Rejected',
  Submitted = 'Submitted',
  Expired = 'Expired Requests',
  // Non Conformity Report
  NcrOpened = 'Opened',
  NcrInAnalysis = 'In Analysis',
  NcrWaitingForApproval = 'Waiting For Approval',
  NcrCompleted = 'Completed',
  NcrCancelled = 'Cancelled',
  // Exterior Wash
  ExteriorWashOffer = 'Exterior Wash Offer',
  // Notification
  BusinessPartnerNotification = 'Company Notification',
  UserNotification = 'Notification Preferences',
  SchneiderPortalCompletions = 'Schneider Portal Completions',
}

export enum PageTitle {
  home = 'OnTrax - Home',
  'wash-list' = 'OnTrax - Drafts ',
  'user-notification' = 'OnTrax - Notification',
  default = 'OnTrax',
  draft = 'OnTrax - Drafts',
  'new-wash-requests' = 'OnTrax - New Requests',
  'needs-action' = 'OnTrax - Needs Action',
  pending = 'OnTrax - Pending',
  hold = 'OnTrax - Hold',
  'credit-hold' = 'OnTrax - Credit Hold',
  accepted = 'OnTrax - Accepted',
  'in-progress' = 'OnTrax - In Progress',
  completed = 'OnTrax - Completed',
  canceled = 'OnTrax - Canceled',
  rejected = 'OnTrax - Rejected',
  submitted = 'OnTrax - Submitted',
  schedule = 'OnTrax - Schedule',
  'activate-terminal' = 'Ontrax - Activate Terminal',
  'hours-of-operation-settings' = 'OnTrax - Hours of Operation',
  'bay-settings' = 'OnTrax - Bay Configuration',
  'velocity-settings' = 'OnTrax - Velocity Configuration',
  dashboard = 'OnTrax - Drafts',
  reports = 'OnTrax - Terminal Reports',
  logout = 'OnTrax - Logout',
  'wash-metrics' = 'OnTrax - Wash Metrics',
  expired = 'OnTrax - Expired Requests',
  SchneiderPortalCompletions = 'Ontrax - Schneider Portal Completions',
  // Non Conformity Report
  ncrCreation = 'OnTrax - Non Conformity Report Creation',
  ncrOpened = 'OnTrax - Non Conformity Report Opened',
  ncrInAnalysis = 'OnTrax - Non Conformity Report In Analysis',
  ncrWaitingForApproval = 'OnTrax - Non Conformity Report Waiting For Approval',
  ncrCompleted = 'OnTrax - Non Conformity Report Completed',
  ncrCancelled = 'OnTrax - Non Conformity Report Cancelled',
  'shift-logs' = 'OnTrax - Shift Logs',
  // Exterior Wash
  exteriorWashOffer = 'OnTrax - Exterior Wash Offer',
  exteriorWashAccept = 'OnTrax - Exterior Wash Offer Accept',
  exteriorWashDecline = 'OnTrax - Exterior Wash Offer Decline',
  // Notification
  businessPartnerNotification = 'OnTrax - Company Notification',
  userNotification = 'OnTrax - Notification Preferences',
}

export class OptionConfig {
  label: string;
  icon: string;
  value: MenuOption | string;
  totalCount?: number;
  newCount?: number;
  availableTo?: string[];
  options?: OptionConfig[];
}

export class SectionConfig {
  label: string;
  icon: string;
  value: string;
  availableTo?: string[];
  totalCount?: number;
  newCount?: number;
  options?: Array<OptionConfig>;
}

export class MenuConfig {
  sections: Array<SectionConfig>;

  constructor() {
    this.sections = [];
  }
}
